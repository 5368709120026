<!-- 解决方案 行业概述 -->
<template>
  <!-- 行业概述 -->
  <div class="business-container">
    <subHead :enVal="'Industry Overview'" :znVal="'行业概述'" />
    <div class="business-text">{{ business.text | myTrim }}</div>
    <div class="business-imgs" v-if="imgLength">
      <!-- imgLength && imgLength == 1 ? 'one-img' : 'much-imgs' -->
      <img :class="[{ 'one-img': imgLength && imgLength == 1 }, { 'two-imgs': imgLength && imgLength == 2 }]"
        v-for="(item, index) in business.Imgs" :src="item.url" alt="" :key="index">
    </div>
  </div>
</template>

<script>
import subHead from "./Subheading.vue"
export default {
  components: {
    subHead
  },
  props: {
    business: {
      type: Object,
      default: {
        text: "在生命科学、人类医药和健康研究领域，实验动物在生命活动中的生理和病理过程，与人类或异种动物都有很多相似之处，并可互为参照。将实验动物进行造模，建立人类疾病的动物模型，对分析疾病的发病机制，解答特定人群对某种疾病的易感性，开发新型药物开发有着重要的作用。研究中需要采集模式动物的各种类型样本供实验研究之用。模式动物样本信息管理系统涵盖实验动物生产管理子系统，模式动物信息数据管理子系统和模式动物样本管理子系统，实现动物实验全生命周期的质量控制和溯源要求，保证动物实验的科学性、准确性和重复性。",
        Imgs: [
          {
            url: require("@/assets/images/cases/content/animalBank01.png")
          },
          {
            url: require("@/assets/images/cases/content/animalBank02.png")
          }
        ]
      }
    },
  },
  data() {
    return {
      imgLength: null
    }
  },
  created() {
    this.imgLength = this.business.Imgs.length
  }
}
</script>

<style lang="less" scoped>
.business-container {
  // background: skyblue;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;

  .business-text {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
    margin: 14px 0 17px;
    letter-spacing: 1px;
    text-align: justify;
  }

  .business-imgs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .two-imgs {
      display: block;
      width: 584px;
      height: 394px;
      margin-bottom: 125px;
    }

    .one-img {
      display: block;
      width: 1200px;
      margin-bottom: 18px;

    }
  }

}
</style>