<!-- 仪器设备共享管理系 -->
<template>
  <div class="instrumentShare">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'仪器设备共享管理系统'" />
    <Business :business="business" />
    <!-- 应用场景 -->
    <div class="instrumentShare-app">
      <div class="ins-app-container">
        <div class="left">
          <Subhead :enVal="'Applications'" :znVal="'应用场景'" :isBlue="1" />
          <div class="text">{{ appsData.text | myTrim }}</div>
        </div>
        <div class="right">
          <img :src="appsData.imgUrl">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import Subhead from "./components/Subheading.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    Subhead
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner2.png"),
      business: {
        text: "随着的科研水平发展，以及实验室环境建设，大型仪器设备日益增多，迫切需要加强科技基础条件平台建设并建立有效的共享机制，以“整合、共享、完善、提高”为原则，减少重复购置和资金浪费，促进实验室资源的专管共享，最大限度地发挥实验室仪器设备及其相关资源的使用效率。共享即意味着实验室将会对外更加地开放，对实验室管理者来说，如何高效率的共享使用仪器？如何科学地开展实验室的各项活动？如何提高日常工作效率？如何更加便利地服务于实验人员？如何保障实验室安全？建设仪器设共享管理信息系统，可覆盖各类科研设施与仪器的统一规范管理，实现专业化、智能化、数字化管理，建立完整的，高效的，安全的仪器设备数字化管理服务平台。",
        Imgs: []
      },
      appsData: {
        text: "注册服务：为实验室内外用户提供统一入口，用户身份注册认证，仪器信息查询，预约等。管理服务：为职能部门提供基于系统管理要求的仪器在线状态查询和使用信息、收费信息、运行绩效等查询统计功能。共享服务：提供仪器共享使用管理功能，包括仪器使用者资质审核、仪器信息共享、仪器预约、来样检测预约、实验室仪器使用情况查询。在线存款及审核、自动获取使用记录、自动扣费、定期结算等。安全管理：根据仪器设备的特点，提供适合的控制器硬件终端以及门禁，自动鉴权，拒绝没有授权的使用请求。使用管理：自动记录仪器使用信息，并自动上传到数据处理中心，协助仪器管理单位管理仪器使用或统计。系统用户包括中山大学南方海洋科学与工程广东省实验室（珠海）仪器设备管理子系统，四川大学华西口腔医院国家重点实验室仪器设备管理等。",
        imgUrl: require("@/assets/images/cases/content/apps-instrumentShare01.png")
      }
    }
  }
}
</script>

<style lang="less" scoped>
.instrumentShare-app {
  max-width: 1920px;
  background-color: #F0F3F9;
  padding: 68px 0;
  // margin-top: 63px ;
  margin: 63px auto 0;

  .ins-app-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1274px;
    margin: 0 auto;

    .left {
      background-color: #fff;
      padding: 37px;
      min-height: 614px;
      box-sizing: border-box;

      .text {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        letter-spacing: 1px;
        margin-top: 49px;
        text-align: justify;
      }
    }

    .right {
      background-color: #9FC2D7;
      min-width: 593px;
      min-height: 614px;
      max-height: 614px;
      padding: 0 91px 10px 46px;
      box-sizing: border-box;
      // overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        // height: 680px;
      }

    }
  }
}
</style>