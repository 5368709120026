<!-- 解决方案 顶部大图 公共组件 -->
<template>
  <div class="img">
    <img :src="bannerImg" alt="" @click="goToLink" />
  </div>
</template>

<script>
export default {
  props: {
    bannerImg: {
      type: String,
      default: "",
    },
  },
  methods: {
    goToLink() {
      this.$emit("goLink");
    },
  },
};
</script>

<style lang="less" scoped>
.img {
  max-width: 1920px;
  // height: 712px;
  // background-color: skyblue;
  margin: 0 auto;
  object-fit: cover;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
